import React from "react";
import styled from "styled-components";
import Box from "@onnit-js/ui/components/box/Box";

interface Props {
    imageUrl: string;
    productUrl?: string;
    alt?: string;
    width?: string | number | [];
}

const ProductImageBox = styled(Box as any)`
    position: relative;
    a,
    img {
        width: 100%;
        display: block;
    }
`;

const ProductImage: React.FC<React.PropsWithChildren<Props>> = ({ imageUrl, alt = "Product Image", productUrl, width = [76, 76, 100], children }) => {
    const image = (
        <img
            src={imageUrl}
            alt={alt}
            style={{ width: "100%" }}
        />
    );

    return (
        <ProductImageBox width={width} mr={2}>
            {children && children}
            {productUrl ? (
                <a href={productUrl}>
                    {image}
                </a>
            ) : image}
        </ProductImageBox>
    );
};

export default ProductImage;
