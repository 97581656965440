import { PromoDiscountTypeEnum } from "@onnit-js/ui/@types/interfaces/PromoDiscountConfig";
import Cart from "../interfaces/cart/Cart";
import CartProduct from "../interfaces/cart/CartProduct";
import CartPromoDiscountConfig from "../interfaces/cart/CartPromoDiscountConfig";

interface DiscountSummary {
    differ: boolean;
    highestPercentage: number | null;
}

export default class SubscriptionDiscountCalculator {
    public readonly DEFAULT_PERCENTAGE = 15;

    getDiscountPercent(cartProduct: CartProduct, promos: CartPromoDiscountConfig[]): number | null {
        if (!cartProduct.product.isEligibleForSubscription) {
            return null;
        }
        return this.getPromoDiscountPercent(cartProduct, promos) ?? this.DEFAULT_PERCENTAGE;
    }

    getDiscountSummary(cart: Cart): DiscountSummary {
        const summary: DiscountSummary = {
            differ: false,
            highestPercentage: null,
        };
        cart.products.forEach((cp) => {
            const percent = this.getDiscountPercent(cp, cart.promo_discounts);
            if (percent === null) {
                return;
            }
            if (summary.highestPercentage === null) {
                summary.highestPercentage = percent;
                return;
            }
            if (percent !== summary.highestPercentage) {
                summary.differ = true;
            }
            if (percent > summary.highestPercentage) {
                summary.highestPercentage = percent;
            }
        });
        return summary;
    }

    private getPromoDiscountPercent(cartProduct: CartProduct, promos: CartPromoDiscountConfig[]): number|null {
        const productId = cartProduct.product.id;
        const promo = promos.find((promo) => promo.promo_discounts_type === PromoDiscountTypeEnum.SUBSCRIPTION
            && (promo.eligible_promo_product_ids.includes(productId)
                || promo.eligible_discount_product_ids.includes(productId)));

        return promo ? promo.unit_discount_ratio * 100 : null;
    }
}
