import React from "react";
import Box from "@onnit-js/ui/components/box/Box";
import { Text } from "@onnit-js/ui/components/text";
import CartProductInterface from "../../../../interfaces/cart/CartProduct";
import QuantityBubble from "./QuantityBubble";
import PriceLabel, { PriceThemeEnum } from "../total/PriceLabel";
import SubscriptionDetailsText from "./subscription/SubscriptionDetailsText";
import ProductImage from "./ProductImage";
import Cart from "../../../../interfaces/cart/Cart";
import ProductWarningSymbols from "./ProductWarningSymbols";
import DiscountBubble from "./DiscountBubble";
import CartPromoDiscountConfig from "../../../../interfaces/cart/CartPromoDiscountConfig";
import PromoDiscountLabel from "./PromoDiscountLabel";

interface Props {
    cart: Cart;
    cartProduct: CartProductInterface;
    showProductWarningSymbols: boolean;
    promoDiscount?: CartPromoDiscountConfig;
}

const CartProductImmutable = ({ cart, cartProduct, promoDiscount, showProductWarningSymbols }: Props) => {
    const { product } = cartProduct;

    // Compute intersection to determine which warning indicators to show for the product.
    const warnings = cart.product_warnings_applicable.filter((warning) => product.warnings.includes(warning));

    return (
        <Box py={3} borderBottom="1px solid" borderColor="grays.1">
            <Box display="flex">
                <ProductImage imageUrl={product.authorityImageUrl} alt={product.name}>
                    <QuantityBubble>{cartProduct.quantity}</QuantityBubble>
                </ProductImage>
                <Box flex="1" display="flex" flexDirection="column" pt={3}>
                    <Box display="flex" mb={1} alignItems="center">
                        <Text
                            display="block"
                            fontWeight="bold"
                            fontSize={[2, 4, "18px"]}
                            flex="1"
                            pr={1}
                            textDecoration="none"
                        >
                            {product.name}
                            {showProductWarningSymbols && <ProductWarningSymbols warnings={warnings} />}
                        </Text>
                    </Box>
                    <Box my={1}>
                        <PriceLabel
                            basePrice={cartProduct.totals.msrp}
                            actualPrice={cartProduct.totals.adjusted_price}
                            theme={PriceThemeEnum.DARK}
                        />
                        <DiscountBubble
                            basePrice={cartProduct.totals.msrp}
                            actualPrice={cartProduct.totals.adjusted_price}
                            mt={2}
                            mr={1}
                        />
                        {promoDiscount && (
                            <PromoDiscountLabel
                                mt={2}
                                promoDiscount={promoDiscount}
                                productId={cartProduct.product.id}
                            />
                        )}
                    </Box>
                    <SubscriptionDetailsText details={cartProduct.subscription_details} mt={1} />
                </Box>
            </Box>
        </Box>
    );
};

export default CartProductImmutable;
